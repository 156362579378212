/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* TABLE STYLE  */
.custom-table {
    width: 100%;
    margin: 0 auto;
    cursor: pointer;
}

.custom-table td, .custom-table th {
    padding: 12px;
    text-align: center;
}

.custom-table tr:hover {
    background-color: #ddd;
}

.custom-table td {
    border-bottom: 1px solid #ddd;
}

.custom-table th {
    background-color: #1E293B;
    color: white;
}

.status {
    border-radius: 3px;
    padding: 8px;
}

.new-row {
    background-color: #DDD !important;
}
/* TABLE STYLE  */

:root {
    --watermelon: #EC607D;
    --space-cadet: #19255B;
    --low-pink: #f6b2ba;
    --turquoise: #7bd0bfff;
    --light-steel-blue: #B3C9E3;
    --desert-sand: #DFCAB0;
}

.rota:hover {
    -webkit-transform: rotate(360deg) scale(1.10);
    -moz-transform: rotate(360deg) scale(1.10);
    -ms-transform: rotate(360deg) scale(1.10);
    -o-transform: rotate(360deg) scale(1.10);
    transform: rotate(360deg) scale(1.10);
    -webkit-transition: -webkit-transform 600ms;
}

//CALENDAR
.ngb-dp-weekday {
    color: #FFF !important;
}
.ngb-dp-month:first-child .ngb-dp-week {
    padding-left: 1.25rem !important;
}
.ngb-dp-month:last-child .ngb-dp-week {
    padding-right: 1.25rem !important;
    margin-bottom: 1rem !important;
}
.ngb-dp-weekdays {
    background-color: var(--space-cadet) !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.btn-light {
    color: var(--space-cadet) !important;
    background-color: #d6e3ed !important;
}
//CALENDAR

.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color: #dcdcdc !important;
    color: #FFF;
    border: 1px solid #113065
}

//mat-icon-button
.mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
//mat-icon-button

.background_image{
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;;
}
